import React, { useState, useEffect } from 'react';
import './App.css';
import axios from "axios";

var Airtable = require('airtable');
var base = new Airtable({ apiKey: 'mykey' }).base('appK0ZuUna5Wx3Br2');

function Batches() {
  base('Batches').select({
    // Selecting the first 3 records in Default:
    // maxRecords: 3,
    view: "Default"
  }).eachPage(function page(records, fetchNextPage) {
    // This function (`page`) will get called for each page of records.

    records.forEach(function (record) {
      console.log('Retrieved', record.get('Name'));
    });

    // To fetch the next page of records, call `fetchNextPage`.
    // If there are more records, `page` will get called again.
    // If there are no more records, `done` will get called.
    // fetchNextPage();

  }, function done(err) {
    if (err) { console.error(err); return; }
  });

}

const client = axios.create({
  baseURL: "https://jsonplaceholder.typicode.com/posts"
});

function MyButton({ text }) {
  return (
    <button className='animate-pulse py-2 px-3 bg-indigo-500 hover:bg-indigo-600 text-white text-sm font-semibold rounded-md shadow focus:outline-none'>
      {text}
    </button>
  );
}

function Card({ post }) {
  return (
    <div className="drop-shadow-2xl overflow-hidden rounded-lg bg-white ">
      <div className='backdrop-blur-sm bg-white/30'>
      <img className=" w-full object-cover rounded-t-lg" src="https://images.unsplash.com/photo-1434394354979-a235cd36269d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2902&q=80" />
      </div>
      <div className="px-4 py-5 sm:px-6 bg-gray-200">
        <h2 className='font-semibold text-xl hover:uppercase'>{post.title}</h2>
        {/* We use less vertical padding on card headers on desktop than on body sections */}
      </div>
      <div className="bg-gray-50 px-4 py-5 sm:p-6 space-x-4">
        {post.body}
      </div>
      < MyButton text={"Open"} />
    </div>
  )
}

const user = {
  name: 'Hedy Lamarr',
  imageUrl: 'https://i.imgur.com/yXOvdOSs.jpg',
  imageSize: 90,
};

const PAIBlock = () => {
  useEffect(() => {
    const fetchData = async () => {
      let data = JSON.stringify({
        "text": [
          "create an EMR for Francesca who lives in Toronto and is 52 years old with arthritis"
        ],
        "link_batch": false,
        "entity_detection": {
          "accuracy": "high_multilingual",
          "return_entity": true,
          "entity_types": [
            {
              "type": "DISABLE",
              "value": [
                "DATE",
                "DURATION"
              ]
            }
          ]
        },
        "processed_text": {
          "type": "SYNTHETIC",
          "synthetic_entity_accuracy": "standard_multilingual",
          "preserve_relationships": true
        }
      });

      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: '34.45.2.171/v3/process/text',
        headers: { 
          'Content-Type': 'application/json', 
          'x-api-key': '<mykeyhere>'
        },
        data: data
      };

      try {
        const response = await axios.request(config);
        console.log(JSON.stringify(response.data));
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []); // Empty dependency array ensures this effect only runs once when the component mounts

  return (
    <div>
      // Your component JSX/HTML here
    </div>
  );
};

function Profile() {
  return (
    <>
      <h1 className="text-3xl font-bold underline">{user.name}</h1>
      <img
        className="avatar"
        src={user.imageUrl}
        alt={'Photo of ' + user.name}
        style={{
          width: user.imageSize,
          height: user.imageSize
        }}
      />
    </>
  );
}

function App() {
  const [title, setTitle] = useState('');
  const [body, setBody] = useState('');
  const [posts, setPosts] = useState([]);
  const [batches, setBatches] = useState([]);

  useEffect(() => {
    const fetchBatch = async () => {
      let response = base('Batches').select({
        // Selecting the first 3 records in Default:
        // maxRecords: 3,
        view: "Default"
      }).all();
      console.log(response);

      setBatches(response.data);
    };
    fetchBatch();
  }, []);

  // GET with Axios
  useEffect(() => {
    const fetchPost = async () => {
      let response = await client.get(); //'?_limit=10'
      // console.log("This is the data");
      // console.log(typeof response);
      // console.log(response);
      setPosts(response.data);
    };
    fetchPost();
  }, []);

  return (
    <div className='min-h-screen bg-cover bg-gradient-to-r from-cyan-500 to-blue-500'>
      <div className="container mx-auto">
        {/* < ShoppingList />
        < MyButton text={"Delete List"} /> */}
        < PAIBlock />
        
        <div className='columns-3 '>
          < Profile />
          <div className="posts-container space-y-4">
            {posts.map((post) => {
              return (
                <div className="container mx-auto " key={post.id}>
                  < Card post={post} />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
